<template>
    <div class="icon-progress-base">
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_12184_8239)">
                <rect x="0.5" width="32" height="32" rx="16" fill="white" />
                <rect x="1.5" y="1" width="30" height="30" rx="15" stroke="#EAECF0" stroke-width="2" />
                <circle cx="16.5" cy="16" r="5" fill="#D0D5DD" />
            </g>
            <defs>
                <clipPath id="clip0_12184_8239">
                    <rect x="0.5" width="32" height="32" rx="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    </div>
</template>

<script setup lang="ts">
</script>

<style>
    .icon-progress-base {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;
        color: rgb(177, 168, 168);
    }
</style>